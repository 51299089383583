<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
          <el-form-item label="">
            <el-input v-model="filters.name" placeholder="鉴定点名称"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="filters.register" placeholder="职业等级方向" filterable clearable>
              <el-option
                  v-for="item in registers"
                  :key="item.id"
                  :label="item.remark"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="filters.skillType" placeholder="关键技能" filterable clearable>
              <el-option
                  v-for="item in skillTypes"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="filters.profession" placeholder="职业功能" filterable clearable>
              <el-option
                  v-for="item in professionType"
                  :key="item.id"
                  :label="item.professionFuncName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="filters.examPosition" placeholder="考位类型" filterable clearable>
              <el-option
                  v-for="item in examPosition"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div style="margin-bottom: 10px">
      <span v-for="item in pointNums">
      {{item.examSeatTypeName}}:{{item.identifyPointNum}}个鉴定点 &nbsp;&nbsp;
    </span>
    </div>
    <!-- 列表 -->
    <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="listLoading"
        ref="multipleTable"
        @current-change='currentChange'
        :highlight-current-row='true'
    >
      <el-table-column type='index' label="序号" width='80' align="center" :index='indexMethod'></el-table-column>
      <el-table-column prop="pointCode" label="鉴定点ID" width="100px" align="center" ></el-table-column>
      <el-table-column prop="professionFuncName" label="职业功能" width="100px" align="center" ></el-table-column>
      <el-table-column prop="" label="技能类型" width="120px"  align="center" >
        <template #default='scope'>
          <el-tag v-if='scope.row.skillType === 1' type='success'>关键技能</el-tag>
          <el-tag v-else type='warning'>非关键技能</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="pointName" label="鉴定点名称"  align="center" ></el-table-column>
      <el-table-column prop="examSeatTypeName" label="考位" width="100px" align="center" ></el-table-column>
      <el-table-column prop="remark" label="职业等级方向" width="120px" align="center" ></el-table-column>
	    <el-table-column prop="ddIdExamVersionValue" label="版本号" width="100"  align="center" ></el-table-column>
      <el-table-column prop="" label="配分" width="100px" align="center" >
        <template #default='scope'>
          <template v-if="scope.row.isRelationBrand">
            <el-popover
                placement="top-start"
                trigger="hover">
              <el-table :data="scope.row.scoreAndQuestionNumList">
                <el-table-column  property="oproomBrandName" width="100px" label="品牌名称"></el-table-column>
                <el-table-column  property="sumScore" width="60px" label="总分"></el-table-column>
                <el-table-column  property="questionNum" width="100px" label="试题数量"></el-table-column>
              </el-table>
              <el-button type="text" slot="reference" >查看</el-button>
            </el-popover>
          </template>
          <span v-else> {{scope.row.sumScore}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sumLimitDuration" label="时长（分钟）" width="120px" align="center" >
        <template #default='scope'>
          {{(scope.row.limitDuration/60)}}
        </template>
      </el-table-column>
      <el-table-column prop="questionNum" label="题干数量" width="90px" align="center" >
        <template #default="scope">
          <el-button type="text" @click="questionNumDialog(scope.row)">{{scope.row.questionNum}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="" label="状态" width="100px" align="center" >
        <template #default='scope'>
          <el-tag v-if='scope.row.ddCommonStatus === 1' type='success'>启用</el-tag>
          <el-tag v-else type='warning'>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="添加时间" width="180px" align="center" >
<!--        <template #default="scope">-->
<!--          {{scope.row.createTime.split('T')[0]}}&nbsp;{{scope.row.createTime.split('T')[1].split('.')[0]}}-->
<!--        </template>-->
      </el-table-column>
    </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.pageIndex"
          :page-sizes="pages.pageArr"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pages.dataCount">
      </el-pagination>
    </el-col>
    <el-dialog
        :title= "editFalse?'编辑':'添加鉴定点'"
        :visible.sync="dialogs.addType.dialog"
        width="40%"
        :close-on-click-modal='false'
    >
      <el-form :model="addForm"  ref="addFormRef" label-width="140px">
        <el-form-item label="鉴定点ID：">
          <el-input style="width: 80%" v-model="addForm.pointCode"></el-input>
        </el-form-item>
        <el-form-item label="职业功能：">
          <el-select v-model="addForm.profession" style="width: 80%" placeholder="请选择职业功能分类" filterable clearable>
            <el-option
                v-for="item in professionType"
                :key="item.id"
                :label="item.professionFuncName"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='技能类型：' prop=''>
          <template>
            <el-radio-group v-model="addForm.skillType">
              <el-radio :label="1">关键技能</el-radio>
              <el-radio :label="2">非关键技能</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
        <el-form-item label="鉴定点名称：">
          <el-input style="width: 80%" v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="职业等级方向：">
          <el-select v-model="addForm.register" style="width: 80%" placeholder="请选择职业等级方向" filterable clearable>
            <el-option
                v-for="item in registers"
                :key="item.id"
                :label="item.remark"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考位：">
          <el-select v-model="addForm.examPosition" style="width: 80%" placeholder="请选择考位类型" filterable clearable>
            <el-option
                v-for="item in examPosition"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配分：" v-if="editFalse">
          <el-input style="width: 80%" disabled  v-model="addForm.allocation" placeholder=""></el-input>
        </el-form-item>
        <el-form-item  label="时长：" >
          <el-input style="width: 80%" v-model="addForm.duration" placeholder=""></el-input>分钟
        </el-form-item>
        <el-form-item label='状态：' prop='webUrl'>
          <template>
            <el-radio-group v-model="addForm.state">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="2">禁用</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
<!--        <el-form-item label="鉴定点ID：" v-if="editFalse">-->
<!--          <el-input style="width: 80%" disabled v-model="addForm.id" placeholder=""></el-input>-->
<!--        </el-form-item>-->
      </el-form>
      <span v-if="!editFalse" slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="addConfirm" :loading="loading">确认</el-button>
            </span>
      <span v-else slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="editConfirm" :loading="loading">确认</el-button>
            </span>
    </el-dialog>
    <el-dialog
        title= "详情"
        :visible.sync="dialogs.detail.dialog"
        width="70%"
        :close-on-click-modal='false'
    >
      <el-table
          :data="questionNumDetail"
          border
          style="width: 100%"
          v-loading="listLoading"
          ref="multipleTable"
          @current-change='currentChange'
          :highlight-current-row='true'
      >
        <el-table-column type='index' label="序号" width='80' align="center" :index='indexMethod'></el-table-column>
        <el-table-column prop="questionCode" label="题目ID" width='140' align="center" ></el-table-column>
        <el-table-column prop="questionContent" label="题干"  align="center" ></el-table-column>
        <el-table-column prop="score" label="分值"  width='120' align="center" ></el-table-column>
        <el-table-column prop="ddIdQuestionType"  width='120' label="是否变体"  align="center" >
          <template #default='scope'>
            <el-tag v-if='scope.row.ddIdQuestionType === 2' type='success'>是</el-tag>
            <el-tag v-else type='warning'>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="ddCommonStatus"  width='120' label="启用状态"  align="center" >
          <template #default='scope'>
            <el-tag v-if='scope.row.ddCommonStatus === 1' type='success'>是</el-tag>
            <el-tag v-else type='warning'>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isBindingVideo" label="视频解析"  width='120'  align="center" >
          <template #default='scope'>
            <el-tag v-if='scope.row.isBindingVideo' type='success'>已上传</el-tag>
            <el-tag v-else type='warning'>未上传</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
	addExamIdentifyPoint,
	updateExamIdentifyPoint,
	getAreaDictionaryList,
	getExamIdentifyPointList,
	getExamSeatTypeList,
	getExamQuestionsByPointId,
	getOproomProfessionFuncList, getOproomProfessionDirList, getExamPointNumByExamSeatType,
} from '../../api/api'

export default {
  components:{Toolbar},
  data() {
    return {
      buttonList:[],
      tableData: [],
      areaArr: [],
      listLoading: false,
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      filters: {
        profession:undefined,
        skillType:undefined,
        name:undefined,
        register:undefined,
        examPosition:undefined,
      },
      dialogs:{
        addType:{
          dialog:false,
        },
        detail:{
          dialog:false,
        }
      },
      addForm:{
        profession:undefined,
        skillType:1,
        name:undefined,
        register:undefined,
        examPosition:undefined,
        allocation:undefined,
        duration:undefined,
        state:1,
        id:undefined,
        pointCode:undefined,
	      oproomBrandId : undefined,
      },
      loading:false,
      addFormRules:[],
      professionType: [
        {value:1,name:'设施监控'},
        {value:2,name:'设施操作'},
        {value:3,name:'设施保养'},
        {value:4,name:'设施维修'},
        {value:5,name:'设施检测'},
      ],
      editFalse:false,
      pointName:[],
      grade:[],
      examPosition:[],
      skillTypes:[
        {value:1,name:'关键技能'},
        {value:2,name:'非关键技能'},
      ],
      registers:[],
      pointNums:[],
      questionNumDetail:[],
	    brandIsDisable : false,
      scoreAndNumList: {}
    }
  },
  methods: {
    rowClass () {
      return 'padding:8px!important;'
    },
    callFunction(item) {
      this[item.func].apply(this, item);
    },
    getListData(){
      this.pages = {
        pageIndex: 1,
        pageSize: 20,
      }
      this.getDataList()
    },
    // 列表查询接口
    getDataList(){
	    //根据考位类型获取鉴定点个数
	    let params3 = {
		    // pageIndex:1,
		    // pageSize:20,
		    examSeatType:this.filters.examPosition,//考位类型
		    pointName:this.filters.name,//鉴定点名称
		    professionDirType:this.filters.register,//职业等级方向类型
		    professionFuncType:this.filters.profession,//职业功能类型
		    skillType:this.filters.skillType,//技能类型
	    }
	    getExamPointNumByExamSeatType(params3).then(res3=>{
		    if (res3.data.success){
			    this.pointNums = res3.data.response
		    }
	    })
      let params = {
        pageIndex: this.pages.pageIndex,//页码
        pageSize: this.pages.pageSize,//页数
        pointName:this.filters.name,//鉴定点名称
        professionDirType:this.filters.register,//职业等级方向类型
        professionFuncType:this.filters.profession,//职业功能类型
        skillType:this.filters.skillType,//技能类型
        examSeatType:this.filters.examPosition,//考位类型
      }
      this.listLoading = true
      getExamIdentifyPointList(params).then(res => {
        var result = res.data
        if(result.success) {
          this.tableData = result.response.data
          this.pages.dataCount = result.response.dataCount
        }
        this.examTypes()
        this.listLoading = false
      })
    },
    //题干数量
    questionNumDialog(val){
      let params = {
        pointId:val.id
      }
      getExamQuestionsByPointId(params).then(res=>{
        if (res.data.success){
          this.questionNumDetail = res.data.response
        }
      })
      this.dialogs.detail.dialog = true ;
    },
    //考位类型
    examTypes(){
      let params = {
        pageIndex:1,
        pageSize:20,
	      ddIdExamSeatStatus:1,
      }
      getExamSeatTypeList(params).then(res=>{
        if (res.data.success){
          this.examPosition = res.data.response.data
        }
      })

      //职业功能
      let params1 = {
        pageIndex:1,
        pageSize:20,
	      ddCommonStatus:1,
      }
      getOproomProfessionFuncList(params1).then(res1=>{
        if (res1.data.success){
          this.professionType = res1.data.response.data
        }
      })

      //职业等级方向
      let params2 = {
        pageIndex:1,
        pageSize:20,
	      ddCommonStatus:1,
      }
      getOproomProfessionDirList(params2).then(res2=>{
        if (res2.data.success){
          this.registers = res2.data.response.data
        }
      })
	    

      
    },
    async handleAdd(){
      this.addForm={
        profession:undefined,
        skillType:1,
        name:undefined,
        register:undefined,
        examPosition:undefined,
        allocation:undefined,
        duration:undefined,
        state:1,
        id:undefined,
	      oproomBrandId : undefined,
      }
      this.dialogs.addType.dialog = true;
    },
    async addConfirm(){
      let params = {
        ddCommonStatus:this.addForm.state,//状态，1:启用;2:禁用
        oproomExamSeatTypeId:this.addForm.examPosition,//考位类型
        oproomProfessionDirId:this.addForm.register,//职业等级方向ID
        oproomProfessionFuncId:this.addForm.profession,//职业功能ID
        pointId:undefined,//鉴定点ID
        pointName:this.addForm.name,//鉴定点名称
        skillType:this.addForm.skillType,//技能类型，1:关键技能;2:非关键技能
        limitDuration:this.addForm.duration*60,//时长
        pointCode:this.addForm.pointCode,
	      oproomBrandId : this.addForm.oproomBrandId?this.addForm.oproomBrandId:0,
      }
      const res = await addExamIdentifyPoint(params)
      if (res.data.success){
        this.getListData()
        this.dialogs.addType.dialog = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }
    },
    handleEdit(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'warning'
        })
        return;
      }
	    // if (this.needData.objBrand[0].isRelationBrand){
			// 	this.brandIsDisable = true
			// }else {
			// 	this.brandIsDisable = false
			// }
      this.editFalse = true;
      this.addForm = {
        state:this.needData.ddCommonStatus,//状态，1:启用;2:禁用
        examPosition:this.needData.oproomExamSeatTypeId,//考位类型
        register:this.needData.oproomProfessionDirId,//职业等级方向ID
        profession:this.needData.oproomProfessionFuncId,//职业功能ID
        id:this.needData.id,//鉴定点ID
        name:this.needData.pointName,//鉴定点名称
        skillType:this.needData.skillType,//技能类型，1:关键技能;2:非关键技能
        duration:this.needData.limitDuration/60,//时长
        allocation:this.needData.sumScore,//配分
        pointCode:this.needData.pointCode,
	      oproomBrandId : this.needData.oproomBrandId?this.needData.oproomBrandId:0,
      }
      this.dialogs.addType.dialog = true;
    },
    async editConfirm(){
      let params = {
        ddCommonStatus:this.addForm.state,//状态，1:启用;2:禁用
        oproomExamSeatTypeId:this.addForm.examPosition,//考位类型
        oproomProfessionDirId:this.addForm.register,//职业等级方向ID
        oproomProfessionFuncId:this.addForm.profession,//职业功能ID
        id:this.needData.id,//鉴定点ID
        pointName:this.addForm.name,//鉴定点名称
        skillType:this.addForm.skillType,//技能类型，1:关键技能;2:非关键技能
        limitDuration:this.addForm.duration*60,//时长
        pointCode:this.addForm.pointCode,
	      oproomBrandId : this.addForm.oproomBrandId?this.addForm.oproomBrandId:0,
      }
      const res = await updateExamIdentifyPoint(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }
      this.editFalse = false;
    },
    //禁用
    async disable(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'warning'
        })
        return;
      }
      this.addForm = {
        state:this.needData.ddCommonStatus,//状态，1:启用;2:禁用
        examPosition:this.needData.oproomExamSeatTypeId,//考位类型
        register:this.needData.oproomProfessionDirId,//职业等级方向ID
        profession:this.needData.oproomProfessionFuncId,//职业功能ID
        id:this.needData.id,//鉴定点ID
        name:this.needData.pointName,//鉴定点名称
        skillType:this.needData.skillType,//技能类型，1:关键技能;2:非关键技能
        duration:this.needData.limitDuration/60,//时长
        allocation:this.needData.sumScore,//配分
        pointCode:this.needData.pointCode,
      }
      let params = {
        ddCommonStatus:2,//状态，1:启用;2:禁用
        oproomExamSeatTypeId:this.addForm.examPosition,//考位类型
        oproomProfessionDirId:this.addForm.register,//职业等级方向ID
        oproomProfessionFuncId:this.addForm.profession,//职业功能ID
        id:this.needData.id,//鉴定点ID
        pointName:this.addForm.name,//鉴定点名称
        skillType:this.addForm.skillType,//技能类型，1:关键技能;2:非关键技能
        limitDuration:this.addForm.duration*60,//时长
        pointCode:this.addForm.pointCode,
      }
      const res = await updateExamIdentifyPoint(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }

    },




    // // 获取省
    // getAreaList() {
    //   var params = {
    //     parentId: 1,
    //   }
    //   getAreaDictionaryList(params).then(res => {
    //     if (res.data.success) {
    //       this.areaArr = res.data.response.reverse();
    //     }
    //   })
    // },
    //获取字符空间
    async getNamespace(){
      let params = {
        pageIndex:undefined,
        pageSize:undefined,
      }
      const res = await  getByNamespace(params)
      console.log(res,'res')
    },


    // 点击table某一行
    currentChange(val) {
      this.needData = val
      console.log(val,'val')
    },
    // 分页
    handleCurrentChange(val) {
      this.pages.pageIndex = val;
      this.getDataList();
    },
    handleSizeChange(val){
      this.pages.pageSize = val;
      this.getDataList();
    },
    // 时间格式化
    formatCreateTime: function(row, column) {
      return !row || row == ""
          ? ""
          : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
    },
    // 表格数据分页条数累计
    indexMethod(index) {
      var currentPage = this.pages.pageIndex
      var pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  },
  watch: {

  },
  created(){
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
    this.getListData();
    // this.getAreaList()
  },
}
</script>

<style lang="stylus" scoped>
.projectName {
  text-align: center;
}
.projectSelect {
  margin-bottom: 15px;
}
.projectTitle {
  margin-bottom: 15px
}
.replyBtn {
  margin: 10px 0;
  color: #409eff;
  cursor: pointer;
  width: 30px;
}
.replyMessage {
  background: #eee;
  line-height: 30px;
  padding: 5px;
  border-radius: 2px;
  margin: 5px 15px;
}
.titleTimer {
  margin-left: 10px;
}
.green_t {
  color: green;
}
.red_t {
  color: red;
}
</style>
